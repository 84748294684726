import React from "react";
const config = require("../../../data/SiteConfig")


class TwoColumnWithSignup extends React.Component {
  getPostList() {
    const postList = [];
    this.props.postEdges.forEach(postEdge => {
      postList.push({
        path: postEdge.node.fields.slug,
        tags: postEdge.node.frontmatter.tags,
        category: postEdge.node.frontmatter.category,
        cover: postEdge.node.frontmatter.cover,
        title: postEdge.node.frontmatter.title,
        date: postEdge.node.fields.date,
        excerpt: postEdge.node.excerpt,
        timeToRead: postEdge.node.timeToRead
      });
    });
    return postList;
  }

  render() {
    const postList = this.getPostList();
    return (
      <div>
        <div className="bg-white pt-10 pb-10 px-4 sm:px-6 lg:pt-10 lg:pb-10 lg:px-8">
        <div className="relative max-w-lg mx-auto lg:max-w-7xl">
            <div>
            <h2 className="text-3xl leading-9 tracking-tight font-extrabold text-gray-900 sm:text-4xl sm:leading-10 ">
                {config.blogTitle}
            </h2>
            <div className="mt-3 sm:mt-4 lg:grid lg:grid-cols-2 lg:gap-5 lg:items-center">
                <p className="text-xl leading-7 text-gray-500">
                    {config.blogDecription}
                </p>
                <form className="mt-6 flex lg:mt-0 lg:justify-end" name="newsletterblogpostlisting" method="post" data-netlify="true" data-netlify-honeypot="bot-field" action="/subscribe-confirm">
                <input aria-label="Email address" name="email" type="email" required className="appearance-none w-full px-4 py-2 border border-gray-300 text-base leading-6 rounded-md text-gray-900 bg-white placeholder-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out lg:max-w-xs" placeholder="Enter your email" />
                <input type="hidden" name="form-name" value="newsletterblogpostlisting" />
                <span className="ml-3 flex-shrink-0 inline-flex rounded-md shadow-sm">
                    <button type="button" className="inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
                        {config.emailSubscribeButtonText}
                    </button>
                </span>
                </form>
            </div>
            </div>
            <div className="mt-6 grid gap-16 border-t-2 border-gray-100 pt-10 lg:grid-cols-2 lg:col-gap-5 lg:row-gap-12">

        {/* Your post list here. */
        postList.map(post => (
          <a href={post.path} key={post.title}>  

            <div>
                <h3 className="mt-2 text-xl leading-7 font-semibold text-gray-900">
                    {post.title}
                </h3>
                <p className="mt-3 text-base leading-6 text-gray-500">
                    {post.excerpt}
                </p>
                <div className="mt-3">
                    {config.blogReadMoreText}
                </div>
            </div>
          </a>
        ))}


            </div>
        </div>
        </div>        
        
      </div>
    );
  }
}

export default TwoColumnWithSignup;
